import React from 'react';
import { Link } from 'react-router-dom';
import VENMO_TEXT from '../../assets/images/logos/venmo-text-blue.png';
import CONSTANTS from '../../utils/tipLink';
const VenmoButton = () => {
	return (
		<Link
			to={CONSTANTS.VENMO}
			target='_blank'
		>
			<button className='hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2'>
				<img
					src={VENMO_TEXT}
					alt='Venmo'
					className='h-5'
				/>
			</button>
		</Link>
	);
};

export default VenmoButton;
