import React, { useState, useEffect } from 'react';
import STYLES from '../../css/styles.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useArtist } from '../../context/ArtistContext.js';
import { useAlert } from '../../context/AlertContext.js';
import scrollToTop from '../../utils/scrollToTop.js';
import ConfirmModal from '../Global/ConfirmModal';
import { useUser } from '../../context/UserContext.js';

export default function ArtistForm() {
	const [formData, setFormData] = useState({});
	const [editing, setEditing] = useState(false);
	const [editingId, setEditingId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [artistIdToDelete, setArtistIdToDelete] = useState(null);
	const navigate = useNavigate();
	const { id } = useParams();
	const { artist, artists, fetchAllArtist, createArtist, updateArtist, deleteArtist } = useArtist();
	const { setAlert } = useAlert();
	const { user } = useUser();

	// Fetch all artists only once or on specific triggers
	useEffect(() => {
		fetchAllArtist();
	}, []);

	// Set up form for editing when id changes and artists are available
	useEffect(() => {
		if (id && artists.length > 0) {
			const artistToEdit = artists.find((a) => a._id === id);
			if (artistToEdit) {
				setFormData(artistToEdit);
				setEditing(true);
				setEditingId(id);
			}
		}
	}, [id, artists]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (editing) {
				await updateArtist(editingId, formData);
				setAlert('success', 'Artist updated successfully');
			} else {
				await createArtist(formData);
				setAlert('success', 'Artist created successfully');
			}
			setFormData({ name: '' });
			setEditing(false);
			setEditingId(null);
			navigate('/artist');
		} catch (err) {
			console.error(err);
			setAlert('error', 'Something went wrong. Please try again.');
		}
	};

	const handleEdit = (item) => {
		setFormData(item);
		setEditing(true);
		setEditingId(item._id);
		scrollToTop();
	};

	const handleDelete = async () => {
		try {
			await deleteArtist(artistIdToDelete);
			setAlert('success', 'Artist deleted successfully');
			fetchAllArtist();
			setIsModalOpen(false);
			navigate('/artist');
		} catch (err) {
			console.error(err);
			setAlert('error', 'Something went wrong. Please try again.');
		} finally {
			setArtistIdToDelete(null);
		}
	};

	return (
		<div className='flex justify-center'>
			<div>
				{user && user.isAdmin && (
					<div>
						<h1 className='mt-4 font-semibold text-2xl text-center'>
							{editing ? 'Edit Artist' : 'Add an Artist'}
						</h1>
						<form
							onSubmit={handleSubmit}
							className={STYLES.FORM}
						>
							<div className={STYLES.FORM_GROUP}>
								<label
									className={STYLES.LABEL}
									htmlFor='name'
								>
									Name:
								</label>
								<input
									className={STYLES.INPUT}
									type='string'
									id='name'
									name='name'
									value={formData.name || ''}
									onChange={handleChange}
								/>
							</div>

							<button
								className={STYLES.BUTTON_BLUE}
								type='submit'
							>
								{editing ? 'Update' : 'Submit'}
							</button>

							{editing && (
								<div className='inline-block'>
									<div className='inline-flex justify-between space-x-10'>
										<button
											className={STYLES.BUTTON_GRAY}
											onClick={() => {
												setEditing(false);
												navigate('/artist');
											}}
											type='button'
										>
											Cancel
										</button>
										<button
											className={STYLES.BUTTON_RED}
											onClick={(e) => {
												e.preventDefault();
												setIsModalOpen(true);
												setArtistIdToDelete(editingId);
											}}
											type='button'
										>
											Delete Artist
										</button>
									</div>
								</div>
							)}
						</form>
					</div>
				)}
				<div>
					<h1 className='mt-4 font-semibold text-2xl text-center'>Artists</h1>
					<p>Click on an artist to view songs by that artist</p>
					<div className=''>
						{artists &&
							artists.map((item) => (
								<div
									key={item._id}
									className={STYLES.LIST_ITEM_CONTAINER}
								>
									<Link
										to={`/artist/${item._id}`}
										className='text-primary font-bold hover:bg-gray-100 px-3 py-1 rounded-md cursor-pointer'
									>
										{item.name}
									</Link>
									{user && user.isAdmin && (
										<Link to={`/artist/edit/${item._id}`}>
											<button
												className={STYLES.BUTTON_TEXT}
												onClick={() => handleEdit(item)}
												type='button'
											>
												Edit
											</button>
										</Link>
									)}
								</div>
							))}
					</div>
				</div>

				<ConfirmModal
					isOpen={isModalOpen}
					message='Are you sure you want to delete this artist? This will also delete all songs associated with this artist.'
					onConfirm={handleDelete}
					onCancel={() => setIsModalOpen(false)}
				/>
			</div>
		</div>
	);
}
