import React, { createContext, useContext, useState, useEffect } from 'react';
import client from '../api/client';
import { useAlert } from './AlertContext';

const SongContext = createContext();
const SongProvider = ({ children }) => {
	const { setAlert } = useAlert();
	const [loading, setLoading] = useState(false);
	const [song, setSong] = useState({});
	const [songs, setSongs] = useState([]);
	const [editing, setEditing] = useState(false);
	const [editingId, setEditingId] = useState(null);
	const [title, setTitle] = useState('');
	const [artist, setArtist] = useState('');
	const [albumArtwork, setAlbumArtwork] = useState('');
	const fetchAllSong = async () => {
		setLoading(true);
		try {
			const { data } = await client.get('/song');
			if (!data) {
				setAlert('error', 'Unable to fetch songs data');
				setLoading(false);
				return console.error('no data');
			}
			setLoading(false);
			setSongs(data);
		} catch (err) {
			setLoading(false);
			return console.error(err);
		}
	};
	const fetchOneSong = async (id) => {
		try {
			setLoading(true);
			const { data } = await client.get(`/song/${id}`);
			if (!data) {
				setAlert('error', 'Unable to fetch song data');
				setLoading(false);
				return console.error('no data');
			}
			setLoading(false);
			setSong(data);
		} catch (err) {
			setLoading(false);
			return console.error(err);
		}
	};
	const createSong = async (newSong) => {
		try {
			const { data } = await client.post('/song', newSong);
			if (!data) {
				setAlert('error', 'Unable to create song');
				return console.log('no data');
			}
			window.location.reload();
		} catch (err) {
			return console.error(err);
		}
	};
	const updateSong = async (id, updatedSong) => {
		try {
			const { data } = await client.put(`/song/${id}`, updatedSong);
			if (!data) {
				setAlert('error', 'Unable to update song');
				return console.log('no data');
			}
			fetchAllSong();
		} catch (err) {
			return console.error(err);
		}
	};
	const deleteSong = async (id) => {
		try {
			const { data } = await client.delete(`/song/${id}`);
			if (!data) {
				setAlert('error', 'Unable to delete song');
				return console.log('no data');
			}
			fetchAllSong();
		} catch (err) {
			return console.error(err);
		}
	};
	const fetchShowSongs = async () => {
		try {
			setLoading(true);
			const { data } = await client.get('/song/show-songs');
			if (!data) {
				setAlert('error', 'Unable to fetch show songs data');
				setLoading(false);
				return console.error('no data');
			}
			setLoading(false);
			setSongs(data);
		} catch (err) {
			return console.error(err);
		}
	};
	return (
		<SongContext.Provider
			value={{
				title,
				setTitle,
				artist,
				setArtist,
				albumArtwork,
				setAlbumArtwork,
				songs,
				song,
				fetchAllSong,
				fetchOneSong,
				createSong,
				updateSong,
				deleteSong,
				fetchShowSongs,
				editing,
				setEditing,
				editingId,
				setEditingId,
				loading,
				setLoading,
			}}
		>
			{children}
		</SongContext.Provider>
	);
};

export const useSong = () => useContext(SongContext);

export default SongProvider;
