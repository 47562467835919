import { TrashIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from '../../context/RequestContext';
import { useUser } from '../../context/UserContext.js';
import STYLES from '../../css/styles.js';
import Spinner from '../Global/Spinner.js';
import Tip from '../Tip.js';
import { useAlert } from '../../context/AlertContext.js';
import client from '../../api/client.js';
import formatDate from '../../utils/formatDate.js';

export default function RequestDetail() {
	const { id } = useParams();
	const { requests, deleteRequest, fetchRequest, fetchAllRequest } = useRequest();
	const [loading, setLoading] = useState(false);
	const { user } = useUser();
	const { setAlert } = useAlert();
	useEffect(() => {
		setLoading(true);
		fetchAllRequest();
		setLoading(false);
	}, []);
	function getFirst140Chars(inputString) {
		if (inputString.length <= 140) {
			return inputString;
		} else {
			return inputString.substring(0, 140);
		}
	}

	const handleDelete = async (id) => {
		try {
			await deleteRequest(id);
		} catch (err) {
			console.error(err);
		}
	};
	const handleClearRequests = async () => {
		try {
			const confirm = window.confirm('Are you sure you want to clear all requests?');
			if (!confirm) return;
			await client.delete('/request');
			setAlert('success', 'Requests cleared successfully');
			fetchAllRequest();
		} catch (error) {
			setAlert('error', error?.response?.data?.error);
			console.error(error?.response?.data?.error);
		}
	};

	return (
		<div className={STYLES.CONTAINER}>
			<h1 className='text-2xl font-semibold text-center'>Requests</h1>

			<Tip />
			{user && user.isAdmin && (
				<div className='flex justify-center mb-4'>
					<button
						className={STYLES.BUTTON_RED}
						onClick={handleClearRequests}
					>
						Clear All Requests
					</button>
				</div>
			)}
			<div className='overflow-x-scroll'>
				{loading ? (
					<Spinner />
				) : (
					<table className='min-w-full divide-y divide-gray-200'>
						{!requests || requests.length === 0 ? (
							<p className='text-center mt-3'>No requests yet...</p>
						) : (
							<>
								<thead className='bg-gray-50'>
									<tr>
										{user && user.isAdmin && (
											<th className='text-left lg:text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
												<TrashIcon className='h-4 w-4' />
											</th>
										)}
										<th className='px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
											#
										</th>
										{user && user.isAdmin && (
											<th className='px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
												$
											</th>
										)}
										<th className='hidden lg:block px-5 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
											Art
										</th>
										<th className='px-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
											Song Info
										</th>
										<th className='px-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
											Requester
										</th>
									</tr>
								</thead>
								<tbody className='bg-white divide-y divide-gray-200'>
									{requests &&
										requests.map((request, i) => (
											<tr key={request._id}>
												{user && user.isAdmin && (
													<td className='whitespace-nowrap text-left text-sm font-medium '>
														<button
															className={`${STYLES.BUTTON_RED}`}
															onClick={() => handleDelete(request._id)}
														>
															<TrashIcon className='h-4 w-4' />
														</button>
													</td>
												)}
												<td className='px-3 py-4 whitespace-nowrap '>
													<div className='flex items-center text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
														{i + 1}
													</div>
												</td>
												{user && user.isAdmin && (
													<td className='max-w-10 px-3 py-4 whitespace-nowrap text-left text-sm font-medium'>
														${request?.tip || 0}
													</td>
												)}
												<td className='hidden lg:block px-5 py-4 whitespace-nowrap '>
													<div className='flex items-center'>
														<img
															src={
																request?.songId?.albumArtwork ||
																'https://res.cloudinary.com/keiserdev/image/upload/v1720304963/album-artwork/default-album-art_kpfmlg.png'
															}
															alt={request?.songId?.title}
															className='h-10 w-10 object-cover rounded '
														/>
													</div>
												</td>
												<td className='px-3 py-4 whitespace-wrap min-w-24 max-w-24'>
													<div className='text-sm font-medium text-gray-900'>
														{request?.songId?.title}
													</div>
													<div className='text-sm font-light text-gray-700'>
														{request?.songId?.artist?.name}
													</div>
												</td>
												<td className='px-3 py-4 min-w-36 max-w-36 lg:max-w-full whitespace-wrap '>
													<div className='text-sm text-gray-900'>
														{request?.requestedBy || 'Anonymous'}
														<div className='text-sm text-gray-600 whitespace-pre-line'>
															{/* show message to admin only */}
															{user && user.isAdmin ? getFirst140Chars(request?.message || '') : ''}
															{/* Date/Time */}
															<div className='mt-1 text-xs tracking-wider text-gray-500'>
																{formatDate(request?.createdAt)}
															</div>
														</div>
													</div>
												</td>
											</tr>
										))}
								</tbody>
							</>
						)}
					</table>
				)}
			</div>
		</div>
	);
}
