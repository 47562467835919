import { jwtDecode } from 'jwt-decode';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Auth, { ACCESS_TOKEN_KEY } from '../utils/auth';
const UserContext = createContext();
const UserProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [user, setUser] = useState({});
	// const navigate = useNavigate();
	const [loginPending, setLoginPending] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	// handles auto login if token is present
	const fetchUser = async () => {
		// check for token
		console.log('inside fetch user');
		const token = Auth.getToken();
		if (token) {
			const isExpired = Auth.isTokenExpired(token);
			if (isExpired) {
				Auth.logout();
				setIsLoggedIn(false);
				setUser({});
				return;
			}
			setLoginPending(true);
			try {
				console.log('token found');
				const token = Auth.getToken();

				if (token !== null) {
					// send to backend to validate
					const res = await Auth.getUser(token);
					if (res.success) {
						setIsLoggedIn(true);
						setUser(res.user);
					} else {
						setUser({});
						setIsLoggedIn(false);
					}
				} else {
					setUser({});
					setIsLoggedIn(false);
				}
				setLoginPending(false);
			} catch (error) {
				setIsLoggedIn(false);
				return console.error('Error: ' + error.message);
			}
		} else {
			console.log(Auth.getToken());
			return console.log('user is not signed in - no token');
		}
	};

	// TODO: Test this
	function checkAndClearJWT() {
		const jwt = Auth.getToken();
		if (jwt) {
			try {
				const decoded = jwtDecode(jwt);
				const now = Date.now() / 1000;
				if (decoded.exp && now > decoded.exp) {
					Auth.logout();
				}
			} catch (error) {
				console.error('Error decoding JWT:', error);
			}
		}
	}

	useEffect(() => {
		fetchUser();
	}, []);
	// fetchUser();
	useEffect(() => {
		console.log({ user, isLoggedIn });
		// getAvatarUrl();
	}, [user]);

	// useEffect(() => {
	// 	if (user && !user.isVerified) {
	// 		navigate('/verify', { replace: true });
	// 	}
	// }, [user]);
	return (
		<UserContext.Provider
			value={{
				isLoggedIn,
				setIsLoggedIn,
				user,
				setUser,
				loginPending,
				setLoginPending,
				isAdmin,
				setIsAdmin,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
