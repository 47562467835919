import Logo from '../../Global/Logo';
import ResetPasswordForm from './ResetPasswordForm';

export default function ResetPassword() {
	return (
		<div
			className={
				'flex min-h-full flex-1 flex-col justify-center align-middle items-center px-6 py-12 lg:px-8'
			}
		>
			<div
				className={
					'bg-gray-200 rounded shadow-lg justify-around w-full md:w-1/2 lg:w-1/3 md:px-4 lg:px-8 py-16'
				}
			>
				<div>
					<Logo className='h-48 w-auto' />
				</div>
				<h4 className='text-center font-semibold mt-2'>Reset Password</h4>
			</div>
			<ResetPasswordForm />
		</div>
	);
}
