import React from 'react';

const Input = ({
	type,
	name,
	id,
	placeholder,
	labelText,
	className,
	value,
	onChange,
	icon = false,
	showLabel = false,
	...props
}) => {
	return (
		<div className=''>
			{type === 'image/*' && id === 'img' ? (
				<div
					className='col-span-full'
					style={{ display: 'none' }}
				>
					<div className='mt-2 flex justify-center '>
						<label
							htmlFor={id}
							className='relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500'
						>
							<div className='text-center'>
								<div className='mt-4 flex text-sm leading-6 text-gray-600'>
									<input
										id={id}
										onChange={onChange}
										value={value ? value : null}
										name={name}
										type='file'
										className='sr-only'
										{...props}
									/>
								</div>
							</div>
						</label>
					</div>
				</div>
			) : (
				<div>
					{showLabel && (
						<label
							htmlFor={id}
							className={'block text-sm font-medium leading-6 text-gray-900'}
						>
							{labelText || placeholder || id}
						</label>
					)}
					<div className=''>
						<input
							type={type}
							name={name}
							onChange={onChange}
							value={value ? value : null}
							id={id}
							className={
								`focus:outline-none w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand_primary-600 sm:text-sm sm:leading-6 ${className}` +
								className
							}
							placeholder={placeholder}
							{...props}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Input;
