import React from 'react';
export default function AppLink({ text, onClick, ...rest }) {
	return (
		<button
			className='text-sm font-medium rounded px-2 py-1 text-gray-900 dark:text-gray-100 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
			{...rest}
			onClick={onClick}
		>
			{text}
		</button>
	);
}
