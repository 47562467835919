import { useState } from 'react';
import { useAlert } from '../../../context/AlertContext';
import client from '../../../api/client';
import Logo from '../../Global/Logo';
import { useNavigate } from 'react-router-dom';
import FormNavigator from '../../AppForm/FormNavigator';
export default function ForgotPassword() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const { setAlert } = useAlert();
	const submitEmail = async (e) => {
		e.preventDefault();
		try {
			const res = await client.post('/user/forgot-password', {
				email,
			});
			console.log(res);
			navigate('/forgot-password/success');
		} catch (err) {
			setAlert(err.response.data.message, 'error');
			console.log(err);
			return;
		}
	};

	return (
		<>
			<div className='flex min-h-full flex-1 flex-col justify-center align-middle items-center px-6 py-12 lg:px-8'>
				<div className='bg-gray-200 rounded shadow-lg justify-around w-full md:w-1/2 lg:w-1/3 md:px-4 lg:px-8 py-16'>
					<div className='sm:mx-auto sm:w-full sm:max-w-sm '>
						<div className='flex justify-center'>
							<Logo className='h-48 w-auto' />
						</div>

						<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
							Forgot Password
						</h2>
					</div>

					<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm b'>
						<form
							className='space-y-6'
							onSubmit={submitEmail}
						>
							<div>
								<label
									htmlFor='email'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Email address
								</label>
								<div className='mt-2'>
									<input
										id='email'
										name='email'
										onChange={(e) => setEmail(e.target.value)}
										type='email'
										autoComplete='email'
										placeholder='Enter your email'
										required
										className=' px-3 block w-full rounded-t-md border-0 py-1.5 text-indigo-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									/>
									<button
										type='submit'
										className='bg-indigo-600 w-full hover:bg-indigo-500 text-black font-semibold px-2 py-1 rounded-b-md'
									>
										Send Reset Email
									</button>
									<FormNavigator
										onLeftLinkPress={() => navigate('/signin')}
										onRightLinkPress={() => navigate('/signup')}
										leftLinkText='Sign In'
										rightLinkText='Sign Up'
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}
