import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert } from '../../../context/AlertContext';
import isProd from '../../../utils/isProd';
import Input from '../../Global/FormElements/Input';
import SnackAlert from '../../Global/SnackAlert';
import Spinner from '../../Global/Spinner';

export default function ResetPasswordForm() {
	const [invalidUser, setInvalidUser] = useState('');
	const [busy, setBusy] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	const [newPassword, setNewPassword] = useState({
		password: '',
		confirmPassword: '',
	});

	useEffect(() => {
		if (snackBtn) {
			if (success === true) {
				console.log('there was success event');
				snackBtn.click();
			}
			if (error && error !== '') {
				console.log('there was an error');
				snackBtn.click();
			}
			if (invalidUser && invalidUser !== '') {
				console.log('there was an invalid User error');
				snackBtn.click();
			}
			return;
		}
		// setError("");
		return;
	}, [error, invalidUser, success]);
	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		// assigns value to name based on name
		setNewPassword({ ...newPassword, [name]: value });
	};
	const { setAlert } = useAlert();

	const snackBtn = document.getElementById('snackBtn');
	const location = useLocation();
	const history = useNavigate();
	const { token, id } = queryString.parse(location.search);

	const baseUrl = isProd ? 'https://theaudiohandbook.com' : 'http://localhost:5051/api/user';

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { password, confirmPassword } = newPassword;
		if (password.trim().length < 8 || password.trim().length > 20) {
			return setError('Password must be between 8 and 20 characters');
		}
		if (password.trim() !== confirmPassword.trim()) {
			return setError('Passwords do not match!');
		}

		try {
			setBusy(true);
			const { data } = await axios.post(`${baseUrl}/reset-password?token=${token}&id=${id}`, {
				password,
			});
			setBusy(false);
			if (data.success) {
				setSuccess(true);
			}
			if (!data.success) return setInvalidUser(data.error);
		} catch (error) {
			setBusy(false);

			// send error from backend
			if (error?.response?.data) {
				// console.error("ERROR:", error?.response?.data);
				const { data } = error.response;
				// console.error("data:", data);
				if (!data.success) return setError(data.error);
				return console.error(error.response.data);
			}
			console.error('error:', error);
		}
	};
	const verifyToken = async () => {
		try {
			setBusy(true);
			const { data } = await axios(`${baseUrl}/verify-token?token=${token}&id=${id}`);
			setBusy(false);
			if (!data.success) return setInvalidUser(data.error);
		} catch (error) {
			setBusy(false);

			// send error from backend
			if (error?.response?.data) {
				console.log(error.response.data);
				const { data } = error.response;
				if (!data.success) return setInvalidUser(data.error);
				return console.error(error.response.data);
			}
			console.error(error);
		}
	};
	// if token is valid, render this form
	useEffect(() => {
		verifyToken();
	}, []);
	if (success) {
		return setAlert('success', 'Reset password successful');
	}

	if (busy)
		return (
			<div>
				<Spinner />
			</div>
		);
	return (
		<div
			style={{
				backgroundColor: 'background.paper',
				mt: 5,
				p: 4,
			}}
		>
			<SnackAlert
				variant={success ? 'success' : 'error'}
				message={success ? 'Reset password successful' : error || invalidUser}
			/>
			<form onSubmit={handleSubmit}>
				<Input
					fullWidth={true}
					label='New Password'
					required={true}
					small={true}
					onChange={handleChange}
					autoComplete='password'
					name='password'
					variant='standard'
					helperText='New Password'
					type='password'
					placeholder='New Password'
					value={newPassword.password}
				/>

				<Input
					fullWidth={true}
					label='Confirm New Password'
					required={true}
					small={true}
					onChange={handleChange}
					autoComplete='password'
					name='confirmPassword'
					variant='standard'
					helperText='Confirm New Password'
					type='password'
					placeholder='Confirm New Password'
					value={newPassword.confirmPassword}
				/>

				<button
					className={`inline-flex items-center rounded bg-brand_primary px-2 py-1 text-md font-semibold text-black shadow-sm hover:bg-brand_primary-400`}
					fullWidth
					type='submit'
				>
					Submit
				</button>
			</form>
		</div>
	);
}
