const STYLES = {
	CONTAINER: ' mx-auto p-6',
	HEADING: 'text-xl md:text-4xl font-bold mb-6',
	H2: 'text-lg md:text-2xl font-semibold mb-4',
	H3: 'text-md md:text-xl font-semibold mb-4 my-4',
	SUBHEADING: 'text-lg md:text-xl font-semibold mb-4',
	SECTION: 'my-4',
	SECTION_TITLE: 'text-xl md:text-2xl font-bold mb-4',
	SECTION_SUBTITLE: 'text-lg md:text-xl font-semibold mb-4',
	SECTION_TEXT: 'text-gray-700 font-medium',
	SECTION_BUTTON: 'bg-primary hover:opacity-85 text-white font-bold py-1 px-2 rounded-md',
	SECTION_BUTTON_TEXT: 'text-primary font-bold py-1 px-2',
	SECTION_BUTTON_ICON: 'text-primary font-bold py-1 px-2',
	SECTION_BUTTON_ICON_TEXT: 'text-primary font-bold py-1 px-2',
	SECTION_BUTTON_ICON_TEXT_SMALL: 'text-primary font-bold py-1 px-2',
	SECTION_BUTTON_ICON_TEXT_LARGE: 'text-primary font-bold py-1 px-2',
	LINK: 'text-primary font-bold py-1 px-2',
	FORM: 'shadow-md p-6 bg-white rounded-md mb-8',
	FORM_GROUP: 'mb-4',
	LABEL: 'block text-gray-700 font-bold mb-2',
	INPUT: 'w-full px-3 py-2 border border-gray-300 rounded-md',
	FORM_BUTTON_CONTAINER: 'flex items-center',
	BUTTON_BLUE: 'bg-primary hover:opacity-85 text-white font-bold py-1.5 px-2.5 rounded-md mr-2',
	BUTTON_FULLWIDTH: 'bg-primary hover:opacity-85 text-white font-bold py-1 px-2 rounded-md w-full',
	BUTTON_GRAY: 'bg-gray-500 text-white font-bold py-1 px-2 rounded-md',
	BUTTON_OFF: 'bg-none text-gray-600 font-bold py-1 px-2 rounded-md',
	BUTTON_YELLOW: 'bg-yellow-500 text-black font-bold py-1 px-2 rounded-md',
	BUTTON_GREEN: 'bg-emerald-700 text-white font-bold py-1 px-2 rounded-md',
	BUTTON_RED: 'bg-rose-600 text-white font-bold py-1 px-2 rounded-md',
	BUTTON_TEXT: 'text-primary font-bold py-1 px-2',
	LIST_ITEM_CONTAINER: 'flex items-center border-b border-gray-200 py-4 justify-between',
	TEXT: 'text-gray-700 font-medium',
};
export default STYLES;
