export const TIPLINK = `https://tr.ee/KVj7G5yOFl`;
export const VENMO = 'https://venmo.com/u/MashingCovers';
export const PAYPAL = 'https://paypal.me/edwardfatula';

const CONSTANTS = {
	TIPLINK,
	VENMO,
	PAYPAL,
};
export default CONSTANTS;
