import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import MainLayout from './layout/MainLayout';

import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ResetPassword/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword/ResetPassword';
import Signup from './components/auth/Signup';
import SuccessPage from './components/auth/Success';
import ResendVerification from './components/auth/Verify/ResendVerification';
import Verify from './components/auth/Verify/Verify';
import VerifySent from './components/auth/Verify/VerifySent';

import ArtistDetail from './components/artist/ArtistDetail';
import ArtistForm from './components/artist/ArtistForm.js';
import Request from './components/request/Request';
import RequestDetail from './components/request/RequestDetail';
import Show from './components/show/Show.js';
import Song from './components/song/Song';
import SongDetail from './components/song/SongDetail';
import Auth, { ACCESS_TOKEN_KEY } from './utils/auth';
import SetListCreator from './components/setlist/SetListCreator.js';

// Component to handle protected routes
const ProtectedRoute = ({ admin = false }) => {
	console.log('protected route');
	const checkAuth = async () => {
		const token = localStorage.getItem(ACCESS_TOKEN_KEY);
		if (token) {
			const { user } = await Auth.getUser(token);

			if (user) {
				if (admin && !user.isAdmin) return redirect('/');
				if (admin && user.isAdmin) return true;
				if (!user.isVerified) return redirect('/verify');
			}
			return true; // allow through access
		}
		return false;
	};

	const isAuth = checkAuth();
	// Redirect to SignIn if no token is found
	if (!isAuth) {
		return (
			<Navigate
				to='/signin'
				replace
			/>
		);
	}

	// Render children if token is found
	return <MainLayout />;
};

export const routes = [
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: '', element: <LandingPage /> },
			{ path: 'signin', element: <Login /> },
			{ path: 'signup', element: <Signup /> },
			{ path: 'verify', element: <Verify /> },
			{ path: 'resend-verification', element: <ResendVerification /> },
			{ path: 'forgot-password', element: <ForgotPassword /> },
			{ path: 'forgot-password/success', element: <SuccessPage /> },
			{ path: 'verify/sent', element: <VerifySent /> },
			{ path: 'reset-pw/*', element: <ResetPassword /> },

			{ path: 'request', element: <Request /> },
			{ path: 'requests', element: <RequestDetail /> },
			{ path: 'song/:id', element: <SongDetail /> },
			{ path: 'artist', element: <ArtistForm /> },
			{ path: 'artist/:id', element: <ArtistDetail /> },
		],
	},
	{
		path: '/',
		element: <ProtectedRoute />,
		loader: async () => {
			const isLoggedIn = Auth.isLoggedIn();
			if (isLoggedIn) {
				return null; // allow through access
			}
			return redirect('/signin');
		},
		children: [
			{ path: 'dashboard', element: <div>Protected Page</div> },

			{ path: 'artist/edit/:id', element: <ArtistForm /> },
			{ path: 'song', element: <Song /> },
			{ path: 'song/edit/:id', element: <Song /> },
			{ path: 'show', element: <Show /> },

			{ path: 'setlist', element: <SetListCreator /> },
		],
	},
	{ '*': { element: <h1>Not Found</h1> } },
];
export default function Router() {
	const router = createBrowserRouter([...routes]);
	return <RouterProvider router={router} />;
}
