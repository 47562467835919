import React from 'react';

const ConfirmModal = ({ isOpen, onCancel, onConfirm, message }) => {
	if (!isOpen) return null;

	return (
		<div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
			<div className='bg-white p-4 rounded'>
				<h3>{message}</h3>
				<div className='mt-4 flex justify-end space-x-3'>
					<button
						onClick={onCancel}
						className='p-2 rounded shadow bg-gray-300'
					>
						Cancel
					</button>
					<button
						onClick={() => {
							onConfirm();
							onCancel();
						}}
						className='p-2 rounded shadow bg-red-500 text-white'
					>
						Delete
					</button>
				</div>
			</div>
		</div>
	);
};

export default ConfirmModal;
