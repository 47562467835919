import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSong } from '../../context/SongContext';
import STYLES from '../../css/styles';
import { useRequest } from '../../context/RequestContext';
import { useUser } from '../../context/UserContext';
import { useAlert } from '../../context/AlertContext';
import Spinner from '../Global/Spinner';

export default function SongDetail() {
	const [formData, setFormData] = useState({});
	const { user } = useUser();
	const { id } = useParams();
	const { song, fetchOneSong, loading: loadingSong, setLoading: setLoadingSong } = useSong();
	const {
		loading,
		setLoading,
		createRequest,
		requestedBy,
		tip,
		setTip,
		setRequestedBy,
		message,
		setMessage,
	} = useRequest();
	const { setAlert } = useAlert();
	const navigate = useNavigate();

	const handleRequest = async (e) => {
		e.preventDefault();
		setLoading(true);
		const newRequest = {
			songId: id,
			requestedBy,
			tip: tip ? parseInt(tip.replace(/\D/g, '')) : 0,
			message,
		};
		await createRequest(newRequest);
		setLoading(false);
		setAlert('success', 'Request sent successfully');
		navigate('/requests');
	};

	useEffect(() => {
		if (id) fetchOneSong(id);
		console.log({ user });
	}, []);
	const handleTipChange = (e) => {
		let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
		if (value.length <= 3) {
			setTip(value ? `$${value}` : ''); // Prepend '$' to the numeric value
		}
	};
	if (loadingSong || loading) return <Spinner />;
	return (
		<div>
			<div className='flex justify-center w-full'>
				{song && (
					<div className={STYLES.FORM + ' ' + 'text-center'}>
						<h2 className={STYLES.HEADING}>{song.title}</h2>
						<p className={STYLES.H2}>{song?.artist?.name}</p>
						<div className='flex justify-center'>
							<img
								src={
									song.albumArtwork ||
									'https://res.cloudinary.com/keiserdev/image/upload/v1720304963/album-artwork/default-album-art_kpfmlg.png'
								}
								alt={song.title}
								className='h-96 w-96 object-cover my-4 rounded'
							/>
						</div>
						<div className='flex flex-col space-y-3 w-full justify-center'>
							<input
								className={STYLES.INPUT}
								placeholder='Your Name'
								name='requestedBy'
								required
								onChange={(e) => setRequestedBy(e.target.value)}
							/>
							<input
								className={STYLES.INPUT}
								placeholder='Message (optional)'
								maxLength={140}
								name='message'
								onChange={(e) => setMessage(e.target.value)}
							/>
							<input
								className={STYLES.INPUT}
								type='text'
								placeholder='How much will you tip for this song?'
								name='tip'
								value={tip || ''}
								onChange={handleTipChange}
							/>
							<button
								onClick={handleRequest}
								className={STYLES.BUTTON_FULLWIDTH}
							>
								Request Song
							</button>

							{user && user.isAdmin && (
								<Link to={`/song/edit/${song._id}`}>
									<button className={STYLES.BUTTON_BLUE}>Edit Song</button>
								</Link>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
