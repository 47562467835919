import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

const SuccessPage = () => {
	return (
		<div className='flex items-center justify-center h-screen bg-gray-100 -m-40'>
			<div className='p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4'>
				<div className='flex-shrink-0'>
					<CheckCircleIcon className='h-12 w-12 text-green-500' />
				</div>
				<div>
					<div className='text-xl font-medium text-black'>Email Sent!</div>
					<p className='text-gray-500'>
						Your email has been submitted successfully. Check your inbox for a reset link to
						complete the password reset.
					</p>
				</div>
			</div>
		</div>
	);
};

export default SuccessPage;
