import React, { useState, useEffect } from 'react';
import STYLES from '../../css/styles.js';
import { Link } from 'react-router-dom';
import { useRequest } from '../../context/RequestContext';
import { useArtist } from '../../context/ArtistContext.js';
import { useSong } from '../../context/SongContext.js';
import { PaintBrushIcon, PhotoIcon } from '@heroicons/react/20/solid';
import BackToTopButton from '../Global/BackToTopButton.js';
import Spinner from '../Global/Spinner.js';

/**
 * This is the make a request form
 * @returns
 */
export default function Request() {
	const [formData, setFormData] = useState({});
	const [editing, setEditing] = useState(false);
	const [editingId, setEditingId] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchType, setSearchType] = useState('text');
	const [selectedEra, setSelectedEra] = useState('');
	const [selectedGenre, setSelectedGenre] = useState('');
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const { requests, createRequest, updateRequest } = useRequest();
	const { loading, setLoading, songs, fetchShowSongs } = useSong();
	const { artists, fetchAllArtist } = useArtist();

	useEffect(() => {
		fetchShowSongs();
		fetchAllArtist();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (editing) {
				await updateRequest(editingId, formData);
			} else {
				await createRequest(formData);
			}
			setFormData({ songId: '', requestedBy: '' });
			setEditing(false);
			setEditingId(null);
		} catch (err) {
			console.error(err);
		}
	};

	const handleEdit = (item) => {
		setFormData(item);
		setEditing(true);
		setEditingId(item._id);
	};

	const handleEraClick = (era) => {
		setSelectedEra(era);
	};

	const handleGenreClick = (genre) => {
		setSelectedGenre(genre);
	};

	const filteredResults = () => {
		if (searchType === 'text') {
			return songs?.filter(
				(song) =>
					(song.title ? song.title.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
					(song.artist && song.artist.name
						? song.artist.name.toLowerCase().includes(searchTerm.toLowerCase())
						: false) ||
					(song.era ? song.era.toLowerCase().includes(searchTerm.toLowerCase()) : false) ||
					(song.genre ? song.genre.toLowerCase().includes(searchTerm.toLowerCase()) : false)
			);
		} else if (searchType === 'era') {
			return songs?.filter((song) =>
				song.era ? song.era.toLowerCase().includes(selectedEra.toLowerCase()) : false
			);
		} else if (searchType === 'genre') {
			return songs?.filter((song) =>
				song.genre ? song.genre.toLowerCase().includes(selectedGenre.toLowerCase()) : false
			);
		}
		return [];
	};

	const sortedResults = React.useMemo(() => {
		const results = filteredResults();
		if (sortConfig.key) {
			return [...results].sort((a, b) => {
				const aValue = sortConfig.key.split('.').reduce((o, i) => o[i], a);
				const bValue = sortConfig.key.split('.').reduce((o, i) => o[i], b);
				if (aValue < bValue) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (aValue > bValue) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return results;
	}, [filteredResults, sortConfig]);

	const requestSort = (key) => {
		let direction = 'ascending';
		if (sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	const getSortIndicator = (key) => {
		if (sortConfig.key !== key) {
			return null;
		}
		return sortConfig.direction === 'ascending' ? '▲' : '▼';
	};

	if (loading) return <Spinner />;
	return (
		<div className=''>
			<BackToTopButton />
			<div>
				<div className='flex flex-col items-center justify-center w-full my-4'>
					<h1 className={STYLES.HEADING}>Request a Song</h1>
					<p className={STYLES.TEXT}>Click Title to Request a Song</p>
					<Link to='/requests'>
						<button className={STYLES.BUTTON_BLUE + ' ' + 'mt-3'}>View Requests</button>
					</Link>
				</div>

				<div className='flex justify-center my-4 mx-4'>
					<div className='grid grid-cols-2'>
						<input
							type='text'
							placeholder='Search...'
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							className={STYLES.INPUT}
						/>
						<select
							value={searchType}
							onChange={(e) => setSearchType(e.target.value)}
							className={STYLES.INPUT + ' mx-2 max-w-1/4'}
						>
							<option value='text'>Artist / Title</option>
							<option value='era'>Era</option>
							<option value='genre'>Genre</option>
						</select>
					</div>
				</div>

				{searchType === 'era' && (
					<div className='grid grid-cols-4 lg:grid-cols-8 justify-center'>
						{['50s', '60s', '70s', '80s', '90s', '00s', '10s', '20s', 'None', 'Mix'].map((era) => (
							<button
								key={era}
								onClick={() => handleEraClick(era)}
								className={`border text-sm rounded px-4 py-2 my-1 mx-2 whitespace-nowrap font-semibold ${
									selectedEra === era ? 'bg-primary text-white' : ''
								}`}
							>
								{era}
							</button>
						))}
					</div>
				)}

				{searchType === 'genre' && (
					<div className='grid grid-cols-4 lg:grid-cols-8 justify-center'>
						{[
							'Rock',
							'Classic Rock',
							'Soft Rock',
							'Oldies',
							'Country',
							'Folk',
							'Original',
							'Rap/R&B',
							'Pop',
							'Medley',
							'Occasion',
						].map((genre) => (
							<button
								key={genre}
								onClick={() => handleGenreClick(genre)}
								className={`border text-sm rounded px-4 py-2 my-1 mx-2 whitespace-nowrap font-semibold ${
									selectedGenre === genre ? 'bg-primary text-white' : ''
								}`}
							>
								{genre}
							</button>
						))}
					</div>
				)}

				<div className='mx-5 my-4'>
					<table className='min-w-full bg-white'>
						<thead>
							<tr>
								<th className='px-5 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
									<div className='flex justify-center'>
										<PhotoIcon className='h-10 w-10  md:h-14 md:w-14 ' />
									</div>
								</th>
								<th
									className='cursor-pointer py-3 md:px-3 text-left text-xs md:text-md font-medium text-gray-500 uppercase tracking-wider'
									onClick={() => requestSort('artist.name')}
									style={{ width: '30%' }}
								>
									Artist {getSortIndicator('artist.name')}
								</th>
								<th
									className='cursor-pointer px-5 py-3 text-left text-xs  md:text-md  font-medium text-gray-500 uppercase tracking-wider'
									onClick={() => requestSort('title')}
									style={{ width: '70%' }}
								>
									Title {getSortIndicator('title')}
								</th>
							</tr>
						</thead>
						<tbody>
							{sortedResults.map((item) => (
								<tr className='border-t'>
									<td className='pl-5 py-3'>
										<img
											src={
												item.albumArtwork ||
												'https://res.cloudinary.com/keiserdev/image/upload/v1720304963/album-artwork/default-album-art_kpfmlg.png'
											}
											alt={item.title || item.name}
											className='h-10 w-10 md:h-14 md:w-14 object-cover rounded'
											style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
										/>
									</td>
									<td
										className='py-3 md:px-3 text-left text-sm  md:text-md '
										style={{ maxWidth: '200px' }}
									>
										{item?.artist?.name || ''}
									</td>
									<td
										className='px-5 py-3 text-left'
										style={{ maxWidth: '250px' }}
									>
										<Link
											key={item._id}
											className='w-full text-left text-black text-sm  md:text-md  font-semibold hover:text-opacity-75'
											to={`/song/${item._id}`}
										>
											{item.title}
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
