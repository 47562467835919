import React from 'react';
import { Link } from 'react-router-dom';
import CONSTANTS from '../../utils/tipLink';

const PayPalButton = () => {
	return (
		<Link
			to={CONSTANTS.PAYPAL}
			target='_blank'
		>
			<button className='focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
				<img
					src='https://www.paypalobjects.com/webstatic/mktg/logo/bdg_secured_by_pp_2line.png'
					border='0'
					alt='PayPal Logo'
				/>
			</button>
		</Link>
	);
};

export default PayPalButton;
