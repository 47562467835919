import * as React from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../context/UserContext';

import client from '../../api/client';

import { getLastNav } from '../../utils/rememberNav';
import FormNavigator from '../AppForm/FormNavigator';
import SubmitBtn from '../AppForm/SubmitButton';
import Input from '../Global/FormElements/Input';
import Logo from '../Global/Logo';
import PasswordInput from './PasswordInput';

export default function Signup() {
	const { setAlert } = useAlert();
	const { user, isLoggedIn } = useUser();
	const navigate = useNavigate();
	const styles = {
		input: {},
	};
	const { setIsLoggedIn, setUser, setLoginPending, loginPending } = useUser();

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = yup.object({
		email: yup.string().email('Invalid email address.').required('Email is missing.'),
		password: yup
			.string()
			.trim()
			.min(8, 'Password must be at least 8 characters.')
			.required('Password is missing.'),
	});

	const handleSignup = async (e) => {
		try {
			e.preventDefault();
			console.log('signing up');
			const values = {
				name: e.target.name.value,
				email: e.target.email.value,
				username: e.target.username.value,
				password: e.target.password.value,
			};
			const validate = validationSchema.validate(values);
			console.log({ validate });

			if (validate.error) {
				setAlert('error', validate.error);
				return console.error(validate.error);
			}
			setLoginPending(true);
			// this is the api call via axios
			const res = await client.post('/user/signup', { ...values });
			console.log({ res });

			// if successful login
			setUser(res.user);
			setIsLoggedIn(true);
			const lastNav = getLastNav();
			if (lastNav) {
				navigate(lastNav);
				setLoginPending(false);
				return;
			}
			setLoginPending(false);

			navigate('/verify/sent');
			window.location.reload();
			return;
		} catch (error) {
			setLoginPending(false);
			setAlert('error', error.message);
			return console.error(error.message);
		}
	};
	if (user && isLoggedIn) return redirect('/dashboard');
	return (
		<div
			className={
				'flex min-h-full flex-1 flex-col justify-center align-middle items-center px-6 py-12 lg:px-8'
			}
		>
			<div
				className={
					'bg-gray-200 rounded shadow-lg justify-around w-full md:w-1/2 lg:w-1/3 md:px-4 lg:px-8 py-16'
				}
			>
				<div className='flex justify-center mt-4'>
					<Logo className='h-48 w-auto' />
				</div>
				<h2 className='mt-10 text-center text-2xl font-light leading-9 tracking-tight text-gray-900'>
					Sign up
				</h2>
				<div>
					<form onSubmit={handleSignup}>
						<Input
							placeholder='Name'
							name='name'
							id='name'
							required
							className='rounded-none rounded-t-md'
							label='Full Name'
							autoComplete='given-name'
							autoFocus
						/>

						<Input
							placeholder='Username'
							name='username'
							className='rounded-none'
							id='username'
							required
							label='Username'
						/>

						<Input
							placeholder='Email'
							name='email'
							id='email'
							required
							autoCapitalize='none'
							className='rounded-none'
							type='text'
							autoComplete='email'
							label='Email Address'
						/>
						<PasswordInput
							autoCapitalize='none'
							placeholder='Password'
							name='password'
							className='rounded-none'
							id='password'
							label='Password'
							required
							type='password'
							autoComplete='new-password'
						/>
						{/* <Input
							autoCapitalize='none'
							placeholder='Password'
							name='password'
							className='rounded-none'
							id='password'
							label='Password'
							required
							type='password'
							autoComplete='new-password'
						/> */}
						<SubmitBtn
							title='Signup'
							type='submit'
							id='submit'
							className={
								'bg-brand_primary-600 w-full hover:bg-brand_primary-500 text-black font-semibold px-2 py-1 rounded-b-md'
							}
							isSubmitting={loginPending === true}
						/>

						<FormNavigator
							onLeftLinkPress={() => navigate('/signin')}
							onRightLinkPress={() => navigate('/forgot-password')}
							leftLinkText='Sign In'
							rightLinkText='Forgot Password'
						/>
						{/* <SocialLogin /> */}
					</form>
				</div>
			</div>
		</div>
	);
}
