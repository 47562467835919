import { PhotoIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import client from '../../api/client.js';
import { useArtist } from '../../context/ArtistContext';
import { useSong } from '../../context/SongContext';
import { ACCESS_TOKEN_KEY } from '../../utils/auth.js';
import STYLES from '../../css/styles.js';
import scrollToTop from '../../utils/scrollToTop.js';
import { useAlert } from '../../context/AlertContext.js';
import BackToTopButton from '../Global/BackToTopButton.js';
import Spinner from '../Global/Spinner.js';

export default function Song() {
	const [file, setFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate();
	const { setAlert } = useAlert();
	const {
		loading,
		setLoading,
		song,
		songs,
		fetchAllSong,
		deleteSong,
		setEditing,
		setEditingId,
		editing,
		editingId,
	} = useSong();
	const { artists, fetchAllArtist } = useArtist();

	const [formData, setFormData] = useState({
		title: '',
		genre: '',
		artist: 'default',
		album: '',
		albumArtwork: '',
		era: '',
		duoOnly: false,
	});

	// Fetch songs and artists on mount
	useEffect(() => {
		fetchAllSong();
		fetchAllArtist();
	}, []);

	// Set form data when id or songs change
	useEffect(() => {
		if (id) {
			const currentSong = songs.find((s) => s._id === id);
			if (currentSong) {
				setFormData({
					title: currentSong.title,
					artist: currentSong.artist._id,
					genre: currentSong.genre,
					album: currentSong.album,
					era: currentSong.era,
					albumArtwork: currentSong.albumArtwork,
					duoOnly: currentSong.duoOnly || false,
				});
				setPreview(currentSong.albumArtwork);
				setEditing(true);
				setEditingId(id);
			}
		} else {
			resetForm();
		}
		scrollToTop();
	}, [id, songs]);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setFile(file);
		setPreview(URL.createObjectURL(file));
	};

	const handleUpload = async (e) => {
		e.preventDefault();
		setLoading(true);
		const uploadFormData = new FormData();
		uploadFormData.append('file', file);
		uploadFormData.append('title', formData.title);
		uploadFormData.append('genre', formData.genre);
		uploadFormData.append('era', formData.era);
		uploadFormData.append('album', formData.album);
		uploadFormData.append('artist', formData.artist);
		uploadFormData.append('duoOnly', formData.duoOnly);

		try {
			let response;
			if (editing) {
				response = await client.put(`/song/${editingId}`, uploadFormData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_KEY)}`,
					},
				});
			} else {
				response = await client.post('/song', uploadFormData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${sessionStorage.getItem(ACCESS_TOKEN_KEY)}`,
					},
				});
			}
			setLoading(false);

			console.log('Song saved successfully:', response.data);
			setAlert('success', 'Song saved successfully');
			fetchAllSong();
			resetForm();
		} catch (error) {
			setLoading(false);
			console.error('Error uploading file:', error);
		}
	};

	const resetForm = () => {
		setFormData({
			title: '',
			artist: 'default',
			genre: '',
			era: '',
			albumArtwork: '',
			album: '',
			duoOnly: false,
		});
		setPreview(null);
		setFile(null);
		setEditing(false);
		setEditingId(null);
		navigate('/song');
	};

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		});
	};

	const handleDelete = async () => {
		try {
			await deleteSong(editingId);
			resetForm();
		} catch (err) {
			console.error(err);
		}
	};
	if (loading) return <Spinner />;
	return (
		<div className='flex justify-center'>
			{artists && artists.length === 0 ? (
				<div className='text-center w-full space-y-3'>
					<p className='my-4 font-semibold'>You need to add an artist first</p>
					<Link
						className='my-4 bg-primary hover:opacity-85 text-white py-2 px-4 rounded-md hover:bg-primary-dark transition duration-200 ease-in-out'
						to='/artist'
					>
						Add Artist
					</Link>
				</div>
			) : (
				<div className='m-3 md:w-2/3'>
					<h1 className='mt-4 font-semibold text-2xl text-center'>
						{editing ? 'Edit Song' : 'Add a Song'}
					</h1>
					<form
						onSubmit={handleUpload}
						className={STYLES.FORM}
					>
						<div className={STYLES.FORM_GROUP}>
							<label
								className={STYLES.LABEL}
								htmlFor='title'
							>
								Title
							</label>
							<input
								className={STYLES.INPUT}
								type='string'
								id='title'
								name='title'
								value={formData.title}
								onChange={handleChange}
							/>
						</div>
						<div className={STYLES.FORM_GROUP}>
							<label
								className={STYLES.LABEL}
								htmlFor='era'
							>
								Era
							</label>

							<select
								className={STYLES.INPUT}
								id='era'
								name='era'
								value={formData.era}
								onChange={handleChange}
							>
								<option value=''>Select an Era</option>
								<option value='60s'>60s</option>
								<option value='70s'>70s</option>
								<option value='80s'>80s</option>
								<option value='90s'>90s</option>
								<option value='2000s'>2000s</option>
								<option value='2010s'>2010s</option>
								<option value='2020s'>2020s</option>
								<option value='Mix'>Mix</option>
								<option value='None'>None</option>
								<option value='other'>Other</option>
							</select>
						</div>
						<div className={STYLES.FORM_GROUP}>
							<label
								className={STYLES.LABEL}
								htmlFor='genre'
							>
								Genre
							</label>

							<select
								className={STYLES.INPUT}
								id='genre'
								name='genre'
								value={formData.genre}
								onChange={handleChange}
							>
								<option value=''>Select a Genre</option>
								<option value='Rock'>Rock</option>
								<option value='Classic Rock'>Classic Rock</option>
								<option value='Soft Rock'>Soft Rock</option>
								<option value='Oldies'>Oldies</option>
								<option value='Country'>Country</option>
								<option value='Folk'>Folk</option>
								<option value='Original'>Original</option>
								<option value='Rap/R&B'>Rap/R&B</option>
								<option value='Pop'>Pop</option>
								<option value='Medley'>Medley</option>
								<option value='Occasion'>Occasion</option>
							</select>
						</div>
						<div className={STYLES.FORM_GROUP}>
							<label
								className={STYLES.LABEL}
								htmlFor='artist'
							>
								Artist
							</label>
							<select
								className={STYLES.INPUT}
								id='artist'
								name='artist'
								value={formData.artist}
								onChange={handleChange}
							>
								<option value='default'>Select Artist</option>
								{artists &&
									artists.map((item) => (
										<option
											key={item._id}
											value={item._id}
										>
											{item.name}
										</option>
									))}
							</select>
						</div>
						<div className={STYLES.FORM_GROUP}>
							<label
								className={STYLES.LABEL}
								htmlFor='cover-photo'
							>
								Album Artwork
							</label>

							{/* Image Upload */}
							<div className='col-span-full'>
								<label className='block text-sm font-medium leading-6 text-gray-900'>
									Album Artwork
								</label>
								<div className='mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'>
									<div className='text-center'>
										{preview ? (
											<img
												src={preview}
												alt='Preview'
												className='mx-auto h-48 w-48 object-cover'
											/>
										) : (
											<PhotoIcon
												aria-hidden='true'
												className='mx-auto h-12 w-12 text-gray-300'
											/>
										)}
										<div className='mt-4 flex text-sm leading-6 text-gray-600'>
											<label
												htmlFor='file-upload'
												className='relative cursor-pointer rounded-md bg-white font-semibold text-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2 hover:text-primary'
											>
												<span>Upload a file</span>
												<input
													id='file-upload'
													name='file-upload'
													type='file'
													className='sr-only'
													onChange={handleFileChange}
												/>
											</label>
											<p className='pl-1'>or drag and drop</p>
										</div>
										<p className='text-xs leading-5 text-gray-600'>PNG, JPG, GIF up to 10MB</p>
									</div>
								</div>
							</div>
						</div>
						<div className={STYLES.FORM_GROUP}>
							<label
								className={STYLES.LABEL}
								htmlFor='duoOnly'
							>
								Duo Only
							</label>
							<input
								className={STYLES.INPUT + ' ' + 'w-5 h-10'}
								type='checkbox'
								id='duoOnly'
								name='duoOnly'
								checked={formData.duoOnly}
								onChange={handleChange}
							/>
						</div>
						<div className='flex justify-between'>
							<div className=''>
								<button
									className={STYLES.BUTTON_BLUE}
									type='submit'
								>
									{editing ? 'Update' : 'Submit'}
								</button>
								{editing && (
									<button
										className={STYLES.BUTTON_GRAY}
										onClick={resetForm}
									>
										Cancel
									</button>
								)}
							</div>
							{editing && (
								<button
									className={STYLES.BUTTON_RED + ' ' + 'ml-2'}
									onClick={() => handleDelete(song._id)}
								>
									Delete Song
								</button>
							)}
						</div>
					</form>
					<BackToTopButton />
					<div>
						{songs &&
							songs.map((item) => (
								<div
									key={item._id}
									className={STYLES.LIST_ITEM_CONTAINER}
								>
									<img
										src={
											item.albumArtwork ||
											'https://res.cloudinary.com/keiserdev/image/upload/v1720304963/album-artwork/default-album-art_kpfmlg.png'
										}
										alt={item.title}
										className='h-16 w-16 object-cover mr-4'
									/>
									<Link
										to={`/song/${item._id}`}
										className='mx-3 font-semibold'
									>
										{item.title}: {item?.artist?.name || 'Unknown Artist'}
									</Link>
									<Link to={`/song/edit/${item._id}`}>
										<button className={STYLES.BUTTON_TEXT}>Edit</button>
									</Link>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	);
}
