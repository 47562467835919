import React, { useEffect, useState } from 'react';
import CONSTANTS from '../utils/tipLink';
import STYLES from '../css/styles';
import { Link, useLocation } from 'react-router-dom';
import PayPalButton from './tip/PayPalButton';
import VenmoButton from './tip/VenmoButton';
import { XMarkIcon } from '@heroicons/react/24/solid';
export default function Tip() {
	const location = useLocation();
	const [path, setPath] = useState(location.pathname);
	const [showTipVendors, setShowTipVendors] = useState(false);
	useEffect(() => {
		setPath(location.pathname);
	}, [location.pathname]);
	return (
		<div className='flex justify-center'>
			<div
				className={
					path !== '/requests'
						? 'text-center mb-5 border border-gray-200 rounded my-5 py-5 px-10  min-w-[30vw]'
						: 'text-center mb-5'
				}
			>
				{path !== '/requests' ? (
					<div className='mb-3'>
						<h3 className='text-2xl font-bold text-center mb-4'>TIPS</h3>
						<p className={STYLES.TEXT + ' ' + 'text-lg'}>Bad for cows.</p>
						<p className={STYLES.TEXT + ' ' + 'text-lg'}>Good for the band.</p>
					</div>
				) : (
					''
				)}
				<div className='mt-5 space-x-5'>
					{path === '/requests' && (
						<Link to={'/request'}>
							<button className={STYLES.BUTTON_BLUE + ' ' + 'px-3 py-2'}>Request a Song</button>
						</Link>
					)}

					<button
						className={
							!showTipVendors
								? STYLES.BUTTON_GREEN + ' ' + 'px-3 py-2'
								: STYLES.BUTTON_OFF + ' ' + 'bg-none text-black px-3 py-2'
						}
						onClick={() => setShowTipVendors(!showTipVendors)}
					>
						{!showTipVendors ? 'Send a Tip' : 'Hide Tip Links'}
					</button>
				</div>
			</div>

			<div className='relative'>
				{showTipVendors && (
					<div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
						<div className='bg-white w-5/6 md:w-4/5 lg:w-1/2 xl:w-1/3 rounded'>
							<div className='px-4 flex justify-between border-b border-gray-300'>
								<h3 className={STYLES.H3 + ' ' + 'text-center'}> Send a Tip </h3>
								<button onClick={() => setShowTipVendors(false)}>
									<XMarkIcon className='h-5 w-5 mr-3' />
								</button>
							</div>
							<div className='w-full text-center my-10 grid grid-cols-1 md:grid-cols-2 items-center gap-10'>
								<VenmoButton />
								<PayPalButton />
							</div>
							<h3 className='px-5 text-center mb-5 text-xs text-gray-500'>
								Requests will be prioritized by tip amount so pay more to skip the line
							</h3>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
