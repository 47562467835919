import axios from 'axios';

const isLocalhost = window.location.hostname.includes('localhost');
const client = axios.create({
	baseURL: isLocalhost ? 'http://localhost:5250/api' : 'https://songrequester.com/api',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
});
export default client;
