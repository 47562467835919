import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';

const MainLayout = () => {
	return (
		<div className=''>
			<div className='sticky top-0 w-full'>
				<Navbar />
			</div>
			<Outlet />
		</div>
	);
};

export default MainLayout;
