import React, { createContext, useContext, useState, useEffect } from 'react';
import client from '../api/client';
import { useAlert } from './AlertContext';

const RequestContext = createContext();
const RequestProvider = ({ children }) => {
	const { setAlert } = useAlert();
	const [request, setRequest] = useState({});
	const [requests, setRequests] = useState([]);
	const [loading, setLoading] = useState(false);
	const [songId, setSongId] = useState('');
	const [requestedBy, setRequestedBy] = useState('');
	const [message, setMessage] = useState('');
	const [tip, setTip] = useState(0);

	const fetchAllRequest = async () => {
		try {
			setLoading(true);
			const { data } = await client.get('/request');
			if (!data) {
				setAlert('error', 'Unable to fetch requests data');
				setLoading(false);
				return console.error('no data');
			}
			setRequests(data);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			return console.error(err);
		}
	};
	const fetchOneRequest = async (id) => {
		try {
			setLoading(true);

			const { data } = await client.get(`/request/${id}`);
			if (!data) {
				setAlert('error', 'Unable to fetch request data');
				setLoading(false);
				return console.error('no data');
			}
			setLoading(false);

			setRequest(data);
		} catch (err) {
			setLoading(false);
			return console.error(err);
		}
	};
	const createRequest = async (newRequest) => {
		try {
			setLoading(true);
			const { data } = await client.post('/request', newRequest);
			if (!data) {
				setAlert('error', 'Unable to create request');
				setLoading(false);
				return console.log('no data');
			}
			setLoading(false);

			setAlert('success', 'Thank you for your request');
		} catch (err) {
			setLoading(false);
			return console.error(err);
		}
	};
	const updateRequest = async (id, updatedRequest) => {
		setLoading(true);

		try {
			const { data } = await client.put(`/request/${id}`, updatedRequest);
			if (!data) {
				setAlert('error', 'Unable to update request');
				setLoading(false);
				return console.log('no data');
			}
			setLoading(false);
			window.location.reload();
		} catch (err) {
			setLoading(false);
			return console.error(err);
		}
	};
	const deleteRequest = async (id) => {
		try {
			await client.delete(`/request/${id}`);
			window.location.reload();
		} catch (err) {
			return console.error(err);
		}
	};

	return (
		<RequestContext.Provider
			value={{
				songId,
				setSongId,
				message,
				setMessage,
				requestedBy,
				setRequestedBy,
				tip,
				setTip,
				requests,
				request,
				fetchAllRequest,
				fetchOneRequest,
				createRequest,
				updateRequest,
				deleteRequest,
				loading,
				setLoading,
			}}
		>
			{children}
		</RequestContext.Provider>
	);
};

export const useRequest = () => useContext(RequestContext);

export default RequestProvider;
