import React, { useEffect, useRef, useState } from 'react';
import { Link, redirect, useNavigate } from 'react-router-dom';
import client from '../../../api/client';
import { useAlert } from '../../../context/AlertContext';
import { useUser } from '../../../context/UserContext';
import { ACCESS_TOKEN_KEY } from '../../../utils/auth';
import Spinner from '../../Global/Spinner';
const Verify = ({ route }) => {
	const isObjValid = (obj) => {
		// convert values to array
		// every method returns true if every value passes the condition
		return Object.values(obj).every((value) => value.trim());
	};
	const [email, setEmail] = useState('');
	const { user } = useUser();
	const inputs = Array(4).fill('');
	const navigate = useNavigate();
	const { setAlert } = useAlert();
	const [OTP, setOTP] = useState({ 0: '', 1: '', 2: '', 3: '' });
	const input = useRef();
	let newInputIndex = 0;
	const [nextInputIndex, setNextInputIndex] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false); // New state for tracking submission status

	const { setIsLoggedIn, setUser, loginPending, setLoginPending } = useUser();

	const submitOTP = async () => {
		setLoginPending(true);
		if (isSubmitting) return; // Prevent multiple submissions
		setIsSubmitting(true); // Disable the submit button

		if (!isObjValid(OTP)) {
			setLoginPending(false);
			return setAlert('error', 'Invalid Passcode');
		}

		if (isObjValid(OTP)) {
			let val = '';
			Object.values(OTP).forEach((v) => {
				val += v;
			});

			const { data } = await client.post('/user/verify', {
				val,
				token: sessionStorage.getItem(ACCESS_TOKEN_KEY),
			});
			if (!data) {
				setLoginPending(false);
				setIsSubmitting(false); // Re-enable the button on failure
				return setAlert('error', data.error);
			}

			setLoginPending(false);
			setIsSubmitting(false);
			setAlert('success', 'Account verified successfully!');
			navigate('/signin', { replace: true });
			window.location.reload();
			return;
		}
	};

	const handleChangeText = (e, index) => {
		const { value } = e.target;
		const newOTP = { ...OTP };
		newOTP[index] = value;
		setOTP(newOTP);

		if (value && index < inputs.length - 1) {
			// Move to next input if value is entered
			setNextInputIndex(index + 1);
		} else if (!value && index > 0) {
			// Move to previous input if backspace is pressed on an empty input
			setNextInputIndex(index - 1);
		}

		// Automatically focus next input after entering a digit
		if (value && index < inputs.length - 1) {
			setNextInputIndex(index + 1);
		}
	};
	// Auto-submit form when all 4 digits are entered
	useEffect(() => {
		const isAllDigitsEntered = Object.values(OTP).every((val) => val.length === 1);
		if (isAllDigitsEntered) {
			submitOTP();
		}
	}, [OTP, submitOTP]);

	useEffect(() => {
		// Automatically submit when all inputs are filled
		if (isObjValid(OTP) && email) {
			submitOTP();
		}
	}, [OTP]); // Dependency array includes OTP

	// handle backspace
	useEffect(() => {
		const handleBackspace = (e) => {
			if (e.keyCode === 8 && nextInputIndex > 0) {
				setNextInputIndex(nextInputIndex - 1);
			}
		};

		window.addEventListener('keydown', handleBackspace);

		return () => {
			window.removeEventListener('keydown', handleBackspace);
		};
	}, [nextInputIndex]);

	useEffect(() => {
		input.current?.focus();
	}, [nextInputIndex]);

	if (user && user.isVerified) {
		console.log(user);
		return redirect('/dashboard');
	}
	return (
		<>
			{loginPending ? <Spinner /> : null}
			<div className='flex justify-center items-center h-screen pb-48 bg-gray-900'>
				<div className='bg-white shadow-xl py-36 px-5 rounded'>
					<h1 className='text-center text-3xl'>Verify Your Account</h1>
					<h2 style={styles.heading}>
						A PIN has been sent to your email for verification. Please verify your email.
					</h2>
					<div className='space-x-4 flex justify-center w-full'>
						{inputs.map((inp, index) => {
							return (
								<div
									style={{ ...styles.inputContainer }}
									className='flex justify-center items-center'
									key={index.toString()}
								>
									<input
										placeholder={'0'}
										className='rounded w-[45px] text-[36px] text-center border border-gray-300'
										onChange={(e) => handleChangeText(e, index)}
										value={OTP[index]}
										maxLength={1}
										ref={nextInputIndex === index ? input : null}
									/>
								</div>
							);
						})}
					</div>
					<div className='text-center flex flex-col justify-between items-center w-full'>
						<div className='mt-3'>
							<button
								className={
									'w-full mt-2 mb-2 bg-brand_primary-600 text-black font-semibold px-2 py-1 rounded-md hover:bg-brand_primary-500 disabled:bg-gray-400 disabled:cursor-not-allowed' +
									' ' +
									'max-w-[230px]'
								}
								onClick={submitOTP}
								disabled={isSubmitting} // Disable button based on isSubmitting state
							>
								<p style={{ ml: 1 }}>Submit</p>
							</button>
						</div>
						<div style={{ mt: 2 }}>
							<Link
								className={
									`inline-flex items-center rounded bg-brand_info px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-brand_info-700 focus:outline-none focus:ring-b-2 focus:ring-b-brand_info-500 focus:ring-offset-2  dark:text-gray-50 dark:bg-gray-900` +
									' ' +
									'mt-10'
								}
								to='/resend-verification'
							>
								Resend verification email
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const styles = {
	container: {
		// justifyContent: "center",
		// display: "flex",
		backgroundColor: 'white',
	},
	heading: {
		color: 'black',
		textAlign: 'center',
		marginBottom: 15,
	},

	otpContainer: {},
	submitIcon: {
		alignSelf: 'center',
		// borderRadius: 50,
		marginTop: 5,
	},
};

export default Verify;
