import React from 'react';
import { Link } from 'react-router-dom';
import STYLES from '../css/styles';
import Logo from './Global/Logo';
import Tip from './Tip';
export default function LandingPage() {
	const btnClasses =
		'bg-primary text-white text-xl font-bold py-4 px-6 text-center rounded-md mt-4';
	return (
		<div className='min-h-[calc(100vh-150px)] flex flex-col justify-center items-center'>
			<Logo className='h-56 w-56 mb-10' />
			<Link
				to='/request'
				className={btnClasses}
			>
				Request a Song
			</Link>

			<Tip />
		</div>
	);
}
