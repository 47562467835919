import React, { useState, useEffect } from 'react';
import STYLES from '../../css/styles.js';
import { Link, useNavigate } from 'react-router-dom';
import { useArtist } from '../../context/ArtistContext';
import { useParams } from 'react-router-dom';
import { useUser } from '../../context/UserContext.js';
import ConfirmModal from '../Global/ConfirmModal.js';

export default function ArtistDetail() {
	const { id } = useParams();
	const { artist, deleteArtist, fetchOneArtist } = useArtist();
	const { user } = useUser();
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (id) fetchOneArtist(id);
	}, [id]);

	const handleDelete = async (id) => {
		try {
			await deleteArtist(id);
			setShowModal(false); // Close the modal after deletion
			navigate('/artist');
		} catch (err) {
			console.error(err);
		}
	};

	const handleCancel = () => {
		setShowModal(false);
		navigate('/artist');
	};

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return (
		<div>
			{user && user.isAdmin && (
				<div className='my-5 mx-2 flex justify-between'>
					<Link
						className={STYLES.BUTTON_BLUE}
						to={`/artist/edit/${artist._id}`}
					>
						Edit Artist
					</Link>
					<button
						className={STYLES.BUTTON_RED}
						onClick={openModal}
					>
						Delete Artist
					</button>
				</div>
			)}

			<ConfirmModal
				isOpen={showModal}
				onCancel={handleCancel}
				onConfirm={() => handleDelete(artist._id)}
				message={`Are you sure you want to delete ${artist.name} and all associated songs?`}
			/>

			<div className={STYLES.CONTAINER}>
				<p className={STYLES.HEADING + ' text-center'}>{artist.name}</p>
				<h3 className={STYLES.H2 + ' ' + 'text-center'}>Songs by {artist.name}</h3>
				<p className={STYLES.H2 + ' ' + 'font-light text-sm text-center'}>
					Click a song to make a request
				</p>
				<div className='grid grid-cols-4'>
					{artist?.songs &&
						artist?.songs.map((song) => (
							<div
								key={song._id}
								className={STYLES.LIST_ITEM_CONTAINER}
							>
								<img
									src={
										song.albumArtwork ||
										'https://res.cloudinary.com/keiserdev/image/upload/v1720304963/album-artwork/default-album-art_kpfmlg.png'
									}
									alt={song.title}
									className='h-16 w-16 object-cover mr-4'
								/>
								<Link
									to={`/song/${song._id}`}
									className='text-primary font-bold hover:bg-gray-100 px-3 py-1 rounded-md cursor-pointer'
								>
									{song.title}
								</Link>
								{user && user.isAdmin && (
									<Link to={`/song/edit/${song._id}`}>
										<button className={STYLES.BUTTON_TEXT}>Edit</button>
									</Link>
								)}
							</div>
						))}
				</div>
			</div>
		</div>
	);
}
