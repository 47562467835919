import React, { useEffect, useState } from 'react';

const PasswordInput = ({
	id,
	placeholder = '',
	labelText,
	name = '',
	value = '',
	showLabel = false,
	className,
	onChange = () => {},
	...rest
}) => {
	const [password, setPassword] = useState('');

	// Regular expressions for the password checks
	const hasEightCharacters = password.length >= 8;
	const hasUpperCase = /[A-Z]/.test(password);
	const hasNumber = /\d/.test(password);
	const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);

	const handleChange = (event) => {
		setPassword(event.target.value);
		onChange(event);
	};

	return (
		<div>
			{showLabel && (
				<label
					htmlFor={id}
					className='block text-sm font-medium leading-6 text-gray-900'
				>
					{labelText || placeholder || id}
				</label>
			)}
			<input
				type='password'
				onChange={handleChange}
				placeholder='Enter your password'
				name={name}
				value={value ? value : null}
				id={id}
				className={
					`focus:outline-none w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand_primary-600 sm:text-sm sm:leading-6 ${className}` +
					className
				}
				{...rest}
			/>
			<ul className='my-4'>
				<li style={{ color: hasEightCharacters ? 'green' : 'red' }}>
					{hasEightCharacters ? '✔️' : '❌'} At least 8 characters
				</li>
				<li style={{ color: hasUpperCase ? 'green' : 'red' }}>
					{hasUpperCase ? '✔️' : '❌'} At least one uppercase letter
				</li>
				<li style={{ color: hasNumber ? 'green' : 'red' }}>
					{hasNumber ? '✔️' : '❌'} At least one number
				</li>
				<li style={{ color: hasSpecialCharacter ? 'green' : 'red' }}>
					{hasSpecialCharacter ? '✔️' : '❌'} At least one special character
				</li>
			</ul>
		</div>
	);
};

export default PasswordInput;
