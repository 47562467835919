import AlertProvider from './context/AlertContext';
import UserProvider from './context/UserContext';
import ArtistProvider from './context/ArtistContext';
import SongProvider from './context/SongContext';
import RequestProvider from './context/RequestContext';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AlertProvider>
		<UserProvider>
			<ArtistProvider>
				<SongProvider>
					<RequestProvider>
						<App />
					</RequestProvider>
				</SongProvider>
			</ArtistProvider>
		</UserProvider>
	</AlertProvider>
);
