import React, { createContext, useContext, useState, useEffect } from 'react';
import client from '../api/client';
import { useAlert } from './AlertContext';

const ArtistContext = createContext();
const ArtistProvider = ({ children }) => {
	const { setAlert } = useAlert();
	const [artist, setArtist] = useState({});
	const [artists, setArtists] = useState([]);

	const [name, setName] = useState('');
	const fetchAllArtist = async () => {
		try {
			const { data } = await client.get('/artist');
			if (!data) {
				setAlert('error', 'Unable to fetch artists data');
				return console.error('no data');
			}
			setArtists(data);
		} catch (err) {
			return console.error(err);
		}
	};
	const fetchOneArtist = async (id) => {
		try {
			const { data } = await client.get(`/artist/${id}`);
			if (!data) {
				setAlert('error', 'Unable to fetch artist data');
				return console.error('no data');
			}
			const a = { ...data.artist, songs: data.songs };
			setArtist(a);
		} catch (err) {
			return console.error(err);
		}
	};
	const createArtist = async (newArtist) => {
		try {
			const { data } = await client.post('/artist', newArtist);
			if (!data) {
				setAlert('error', 'Unable to create artist');
				return console.log('no data');
			}
			window.location.reload();
		} catch (err) {
			return console.error(err);
		}
	};
	const updateArtist = async (id, updatedArtist) => {
		try {
			const { data } = await client.put(`/artist/${id}`, updatedArtist);
			if (!data) {
				setAlert('error', 'Unable to update artist');
				return console.log('no data');
			}
			window.location.reload();
		} catch (err) {
			return console.error(err);
		}
	};
	const deleteArtist = async (id) => {
		try {
			const { data } = await client.delete(`/artist/${id}`);
			if (!data) {
				setAlert('error', 'Unable to delete artist');
				return console.log('no data');
			}
			window.location.reload();
		} catch (err) {
			return console.error(err);
		}
	};

	return (
		<ArtistContext.Provider
			value={{
				name,
				setName,
				artists,
				artist,
				fetchAllArtist,
				fetchOneArtist,
				createArtist,
				updateArtist,
				deleteArtist,
			}}
		>
			{children}
		</ArtistContext.Provider>
	);
};

export const useArtist = () => useContext(ArtistContext);

export default ArtistProvider;
