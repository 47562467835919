import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import client from '../../api/client';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../context/UserContext';
import { ACCESS_TOKEN_KEY } from '../../utils/auth';
import { getLastNav } from '../../utils/rememberNav';
import AppFormContainer from '../AppForm/AppFormContainer';
import FormNavigator from '../AppForm/FormNavigator';
import Input from '../Global/FormElements/Input';
import Logo from '../Global/Logo';

export default function Login() {
	const { setIsLoggedIn, isLoggedIn, setUser, setLoginPending } = useUser();
	const { setAlert } = useAlert();

	const initialValues = {
		email: '',
		password: '',
	};

	const navigate = useNavigate();

	const validationSchema = yup.object({
		email: yup.string().email('Invalid email address.').required('Email is missing.'),
		password: yup
			.string()
			.trim()
			.min(8, 'Password must be at least 8 characters.')
			.required('Password is missing.'),
	});

	const handleLogin = async (e) => {
		try {
			e.preventDefault();
			const values = {
				email: e.target.email.value,
				password: e.target.password.value,
			};
			console.log('logging in');
			console.log({ values });
			setLoginPending(true);
			// this is the api call via axios
			const { data } = await client.post('/user/signin', {
				...values,
			});

			if (!data.success) {
				setAlert('error', data.error);
				console.error(data.error);
				return console.log(data.error);
			}
			if (data) {
				setLoginPending(false);

				// if successful login
				setUser(data.user);
				if (data.token) sessionStorage.setItem(ACCESS_TOKEN_KEY, data.token);
				setIsLoggedIn(true);
				// if (data.user && data.user.isVerified) {
				// 	navigate('/apps/song', { replace: true });
				// } else {
				// 	navigate('/verify', { replace: true });
				// }
				// const lastNav = getLastNav();
				// if (lastNav) {
				// 	navigate(lastNav);
				// 	return;
				// } else
				navigate('/requests');
			}
		} catch (error) {
			setLoginPending(false);
			setAlert('error', error?.response?.data?.error);
			return console.error(error?.response?.data?.error);
		}
	};

	if (isLoggedIn) navigate('/requests');
	else
		return (
			<div
				className={
					'flex min-h-full flex-1 flex-col justify-center align-middle items-center px-6 py-12 lg:px-8'
				}
			>
				<div
					className={
						'bg-gray-200 rounded shadow-lg justify-around w-full md:w-1/2 lg:w-1/3 md:px-4 lg:px-8 py-16'
					}
				>
					<div className='flex justify-center mt-4'>
						<Logo className='h-48 w-auto' />
					</div>
					<h2 className='mt-5 text-center text-2xl font-light leading-9 tracking-tight text-gray-900 '>
						Sign in
					</h2>
					<AppFormContainer>
						<div className='mt-10 sm:mx-auto w-full'>
							<form
								className='space-y-6'
								onSubmit={handleLogin}
							>
								<div className='mx-3'>
									<Input
										id='email'
										autoComplete='email'
										variant='filled'
										placeholder='Email'
										label='Email'
										required
										name='email'
										autoCapitalize='none'
										className='rounded-t-md rounded-b-none'
									/>
									<Input
										id='password'
										required
										variant='filled'
										autoCapitalize='none'
										autoComplete='current-password'
										placeholder='Password'
										name='password'
										type='password'
										className='rounded-none'
										label='Password'
									/>
									<button
										type='submit'
										className={
											'bg-primary w-full hover:bg-primary text-white font-semibold px-2 py-1 rounded-b-md'
										}
									>
										Login
									</button>
								</div>
							</form>
						</div>
					</AppFormContainer>
					<FormNavigator
						onLeftLinkPress={() => navigate('/signup')}
						onRightLinkPress={() => navigate('/forgot-password')}
						leftLinkText='Sign Up'
						rightLinkText='Forgot Password'
					/>
				</div>
			</div>
		);
}
