import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import LOGO from '../assets/images/logos/logo-heart.png';
import { useUser } from '../context/UserContext';
import Auth from '../utils/auth';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}
function LogoutButton() {
	const { user, isLoggedIn } = useUser();
	return (
		<div>
			{user && isLoggedIn && (
				<button
					onClick={() => Auth.logout()}
					className='rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white relative flex bg-primary focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
				>
					Logout
				</button>
			)}
		</div>
	);
}
export default function Navbar() {
	const { user, isLoggedIn } = useUser();
	const location = useLocation();
	let navigation = [];

	if (user && isLoggedIn && user.isAdmin) {
		navigation = [
			{
				name: 'Request a Song',
				href: '/request',
				current: location.pathname === '/request',
			},
			{
				name: 'Artists',
				href: '/artist',
				current: location.pathname === '/artist',
			},
			{
				name: 'Songs',
				href: '/song',
				current: location.pathname === '/song',
			},
			// {
			// 	name: 'Set List Creator',
			// 	href: '/setlist',
			// 	current: location.pathname === '/setlist',
			// },
			{
				name: 'Show',
				href: '/show',
				current: location.pathname === '/show',
			},
			{
				name: 'Request List',
				href: '/requests',
				current: location.pathname === '/requests',
			},
		];
	} else {
		navigation = [
			{
				name: 'Request a Song',
				href: '/request',
				current: location.pathname === '/request',
			},
			{
				name: 'Request List',
				href: '/requests',
				current: location.pathname === '/requests',
			},
		];
	}
	let rightNavigation = [];
	if (!isLoggedIn)
		rightNavigation = [
			// { name: 'Sign Up', href: '/signup', current: location.pathname === '/signup' },
			{ name: 'Sign In', href: '/signin', current: location.pathname === '/signin' },
		];

	return (
		<Disclosure
			as='nav'
			className='bg-primary'
		>
			{({ open }) => (
				<>
					<div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
						<div className='relative flex h-16 items-center justify-between'>
							<div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
								{/* Mobile menu button*/}
								<Disclosure.Button className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
									<span className='absolute -inset-0.5' />
									<span className='sr-only'>Open main menu</span>
									{open ? (
										<XMarkIcon
											className='block h-6 w-6'
											aria-hidden='true'
										/>
									) : (
										<Bars3Icon
											className='block h-6 w-6'
											aria-hidden='true'
										/>
									)}
								</Disclosure.Button>
							</div>
							<div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
								<div className='flex flex-shrink-0 items-center'>
									<Link to='/'>
										<img
											className='h-10 w-auto object-cover'
											src={LOGO}
											alt='Your Company'
										/>
									</Link>
								</div>
								<div className='hidden sm:ml-6 sm:block'>
									<div className='flex space-x-4'>
										{navigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className={classNames(
													item.current
														? 'bg-gray-900 text-white'
														: 'text-gray-300 hover:bg-gray-700 hover:text-white',
													'rounded-md px-3 py-2 text-sm font-medium'
												)}
												aria-current={item.current ? 'page' : undefined}
											>
												{item.name}
											</a>
										))}
									</div>
								</div>
							</div>
							<div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
								{rightNavigation.map((item) => (
									<Link
										to={item.href}
										key={item.name}
										className={classNames(
											item.current
												? 'bg-gray-900 text-white'
												: 'text-gray-300 hover:bg-gray-700 hover:text-white',
											'rounded-md px-3 py-2 text-sm font-medium'
										)}
										aria-current={item.current ? 'page' : undefined}
									>
										{item.name}
									</Link>
								))}
								{user && isLoggedIn && <LogoutButton />}
							</div>
						</div>
					</div>

					<DisclosurePanel className='sm:hidden'>
						<div className='space-y-1 px-2 pb-3 pt-2'>
							{navigation.map((item) => (
								<DisclosureButton
									key={item.name}
									as='a'
									href={item.href}
									className={classNames(
										item.current
											? 'bg-gray-900 text-white'
											: 'text-gray-300 hover:bg-gray-700 hover:text-white',
										'block rounded-md px-3 py-2 text-base font-medium'
									)}
									aria-current={item.current ? 'page' : undefined}
								>
									{item.name}
								</DisclosureButton>
							))}
						</div>
					</DisclosurePanel>
				</>
			)}
		</Disclosure>
	);
}
