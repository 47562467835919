import React, { useEffect, useState } from 'react';
import client from '../../api/client';
import STYLES from '../../css/styles';
import { useAlert } from '../../context/AlertContext';

export default function Show() {
	const [formData, setFormData] = useState({
		duoOnly: false,
	});
	const { setAlert } = useAlert();
	const handleChange = (e) => {
		const { name, checked, type, value } = e.target;
		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		});
	};

	const fetchShow = async () => {
		try {
			const response = await client.get('/show');
			console.log('Show:', response.data);
			setFormData(response.data.show); // Assuming response.data.show contains the correct structure
		} catch (error) {
			console.error('Error fetching show:', error);
		}
	};

	const updateShow = async (e) => {
		e.preventDefault();

		try {
			const { data } = await client.put('/show', formData);
			console.log('Show updated:', data);
			setAlert('success', 'Show updated successfully');
		} catch (error) {
			console.error('Error updating show:', error);
			setAlert('error', 'Something went wrong. Please try again.');
		}
	};

	useEffect(() => {
		fetchShow();
	}, []); // No dependencies, fetch once on mount

	return (
		<div className={STYLES.CONTAINER}>
			<h1 className='text-2xl mt-3 font-semibold text-center'>Set Show Type</h1>
			<p className='text-center'>This will change what songs display on the Make a Request page</p>
			<form
				onSubmit={updateShow}
				className={STYLES.FORM}
			>
				<div className='flex justify-center'>
					<div className='flex space-x-4 space-y-5 items-baseline'>
						<label
							htmlFor='duoOnly'
							className={STYLES.LABEL}
						>
							Duo Show?
						</label>
						<input
							type='checkbox'
							id='duoOnly'
							name='duoOnly'
							className={STYLES.CHECKBOX}
							checked={formData.duoOnly || false} // Ensure boolean value
							onChange={handleChange}
						/>
					</div>
				</div>
				<button
					className={`${STYLES.BUTTON_FULLWIDTH} my-5`} // Concatenate class names
					type='submit'
				>
					Update
				</button>
			</form>
		</div>
	);
}
