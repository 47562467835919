import Spinner from '../../Global/Spinner';
import React, { useState } from 'react';

import Input from '../../Global/FormElements/Input';
import client from '../../../api/client';
import { useAlert } from '../../../context/AlertContext';
import { useNavigate } from 'react-router-dom';
export default function ResendVerification({}) {
	const [showForm, setShowForm] = useState(false);
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const toggleElements = () => {
		showForm ? setShowForm(false) : setShowForm(true);
	};
	const navigate = useNavigate();
	const { setAlert } = useAlert();
	const handleSubmit = async (e) => {
		let res;
		try {
			e.preventDefault();
			setLoading(true);
			e.preventDefault();
			res = await client.post('/user/resend-verification', {
				email,
			});
			setLoading(false);
			navigate('/verify/sent');
			return;
		} catch (error) {
			setLoading(false);

			setAlert('error', error.response.data.error);
			return console.error(error);
		}
	};
	return (
		<div className='flex justify-center items-center h-screen pb-48 bg-gray-900'>
			<div className='bg-white shadow-xl py-36 px-48 rounded'>
				<form onSubmit={handleSubmit}>
					<Input
						id='resend-email'
						placeholder='email for reverify'
						onChange={(e) => setEmail(e.target.value)}
						autoComplete='email'
						className={'w-full rounded-b-none'}
					/>
				</form>
				<button
					className={
						'bg-brand_primary-600 w-full hover:bg-brand_primary-500 text-black font-semibold px-2 py-1 rounded-b-md' +
						' ' +
						''
					}
					disabled={loading}
					onClick={handleSubmit}
					id='submit-btn'
					type='submit'
				>
					{loading ? <Spinner /> : 'Resend Verification Email'}
				</button>
			</div>
		</div>
	);
}
