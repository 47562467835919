import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactToPrint from 'react-to-print';
import client from '../../api/client';
import { useSong } from '../../context/SongContext';

const initialSets = [
	{
		id: 'set-1',
		name: 'Set 1',
		songs: [],
	},
];

function SetListCreator() {
	const { songs, fetchAllSong } = useSong();
	const [songList, setSongList] = useState(songs || []);
	const [sets, setSets] = useState(initialSets);
	const printRef = useRef();

	useEffect(() => {
		fetchAllSong().then(() => {
			setSongList(songs);
		});
	}, []);

	useEffect(() => {
		setSongList(songs);
	}, [songs]);

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const { source, destination } = result;

		if (source.droppableId === destination.droppableId) {
			const items = Array.from(
				source.droppableId === 'all-songs'
					? songList
					: sets.find((set) => set.id === source.droppableId).songs
			);
			const [reorderedItem] = items.splice(source.index, 1);
			items.splice(destination.index, 0, reorderedItem);

			if (source.droppableId === 'all-songs') {
				setSongList(items);
			} else {
				setSets(
					sets.map((set) => {
						if (set.id === source.droppableId) {
							return { ...set, songs: items };
						}
						return set;
					})
				);
			}
		} else {
			const sourceItems =
				source.droppableId === 'all-songs'
					? songList
					: sets.find((set) => set.id === source.droppableId).songs;
			const destItems = sets.find((set) => set.id === destination.droppableId).songs;
			const [movedItem] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, movedItem);

			if (source.droppableId === 'all-songs') {
				setSongList(sourceItems);
			} else {
				setSets(
					sets.map((set) => {
						if (set.id === source.droppableId) {
							return { ...set, songs: sourceItems };
						}
						if (set.id === destination.droppableId) {
							return { ...set, songs: destItems };
						}
						return set;
					})
				);
			}
		}
	};

	const addSet = () => {
		const newSet = {
			id: `set-${sets.length + 1}`,
			name: `Set ${sets.length + 1}`,
			songs: [],
		};
		setSets([...sets, newSet]);
	};

	const saveSets = async () => {
		const { data } = await client.post('/setlist', { sets });
		console.log(data);
	};

	return (
		<>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<div className='p-4 flex'>
					<div className='w-1/3'>
						<h2 className='text-lg font-bold mb-3'>All Songs</h2>
						<Droppable droppableId='all-songs'>
							{(provided) => (
								<ul
									{...provided.droppableProps}
									ref={provided.innerRef}
									className='list-none space-y-2 mb-4'
								>
									{songList.map((song, index) => (
										<Draggable
											key={song._id}
											draggableId={song._id}
											index={index}
										>
											{(provided) => (
												<li
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className='bg-gray-200 p-2 rounded shadow cursor-move'
												>
													<p className='font-semibold inline-block'>{song.title}</p>
													<p>{song?.artist?.name}</p>
												</li>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</ul>
							)}
						</Droppable>
					</div>
					<div className='w-2/3 pl-4'>
						<h2 className='text-lg font-bold mb-3'>Set Lists</h2>
						<button
							onClick={addSet}
							className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4'
						>
							Add Set
						</button>
						{sets.map((set) => (
							<div
								key={set.id}
								className='mb-4'
							>
								<h3 className='font-bold text-lg py-4'>{set.name}</h3>
								<Droppable droppableId={set.id}>
									{(provided) => (
										<ul
											{...provided.droppableProps}
											ref={provided.innerRef}
											className='list-none space-y-2 py-5'
										>
											{set.songs.map((song, idx) => (
												<Draggable
													key={song._id}
													draggableId={song._id}
													index={idx}
												>
													{(provided) => (
														<li
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className='bg-gray-200 p-2 rounded shadow cursor-move'
														>
															<p className='font-semibold inline-block'>{song.title}</p>
															<p>{song?.artist?.name}</p>
														</li>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</ul>
									)}
								</Droppable>
							</div>
						))}
						<button
							onClick={saveSets}
							className='bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded mt-4'
						>
							Save Set List
						</button>
					</div>
				</div>
			</DragDropContext>
			<ReactToPrint
				trigger={() => (
					<button className='bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded absolute bottom-4 right-4'>
						Print Set List
					</button>
				)}
				content={() => printRef.current}
			/>
			<div style={{ display: 'none' }}>
				<div
					ref={printRef}
					className='p-4'
				>
					{sets.map((set, index) => (
						<div
							key={index}
							className='mb-4'
						>
							<h3 className='font-bold text-lg'>{set.name}</h3>
							<ul className='list-none'>
								{set.songs.map((song, idx) => (
									<li
										key={idx}
										className='text-md'
									>
										{song.title}
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			</div>
		</>
	);
}

export default SetListCreator;
