import React from 'react';
import LOGOIMG from '../../assets/images/logos/logo.png';
import { Link } from 'react-router-dom';
export default function Logo({ linkHref = '/', ...props }) {
	return (
		<div>
			<Link to={linkHref}>
				<img
					src={LOGOIMG}
					alt='logo'
					{...props}
				/>
			</Link>
		</div>
	);
}
